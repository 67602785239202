import React from 'react';
import { Link } from 'gatsby';
import Layout from '../components/layout/layout';
import SEO from '../components/seo';
import { ChevronRightIcon } from '@heroicons/react/solid';
import { BookOpenIcon, RssIcon } from '@heroicons/react/outline';

const NotFoundPage = () => {
  const links = [
    {
      title: 'Voter Guides',
      description: 'Learn how to vote for maximum impact',
      icon: BookOpenIcon,
      href: '/voter-guide/',
    },
    {
      title: 'Blogs',
      description: 'Read our newsletters',
      icon: RssIcon,
      href: '/blog/',
    },
  ];

  return (
    <Layout>
      <SEO title="404: Not found" />
      <main className="max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
        <div className="max-w-xl mx-auto py-16 sm:py-24">
          <div className="text-center">
            <p className="text-sm font-semibold text-blue-600 uppercase tracking-wide">404 error</p>
            <h1 className="mt-2 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
              Just like good governance in SF, this page could not be found
            </h1>
            <p className="mt-2 text-lg text-gray-500">
              But we think you would like some of our other content!
            </p>
          </div>
          <div className="mt-12">
            <h2 className="text-sm font-semibold text-gray-500 tracking-wide uppercase">
              Popular pages
            </h2>
            <ul
              role="list"
              className="mt-4 border-t border-b border-gray-200 divide-y divide-gray-200"
            >
              {links.map((link, linkIdx) => (
                <li key={linkIdx} className="relative py-6 flex items-start space-x-4">
                  <div className="flex-shrink-0">
                    <span className="flex items-center justify-center h-12 w-12 rounded-lg bg-blue-50">
                      <link.icon className="h-6 w-6 text-blue-700" aria-hidden="true" />
                    </span>
                  </div>
                  <div className="min-w-0 flex-1">
                    <h3 className="text-base font-medium text-gray-900">
                      <span className="rounded-sm focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-blue-500">
                        <Link to={link.href} className="focus:outline-none">
                          <span className="absolute inset-0" aria-hidden="true" />
                          {link.title}
                        </Link>
                      </span>
                    </h3>
                    <p className="text-base text-gray-500">{link.description}</p>
                  </div>
                  <div className="flex-shrink-0 self-center">
                    <ChevronRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                  </div>
                </li>
              ))}
            </ul>
            <div className="mt-8">
              <Link to="/" className="text-base font-medium text-blue-600 hover:text-blue-500">
                Or go back home<span aria-hidden="true"> &rarr;</span>
              </Link>
            </div>
          </div>
        </div>
      </main>
    </Layout>
  );
};

export default NotFoundPage;
